import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import http from "app/requests/axiosInstance";
import { DualPricingPaymentTypes } from "app/sales/useSales";

export enum SaleStatusTypes {
  OPEN = "open",
  CLOSED = "closed",
  VOID = "void",
}

export interface SalesSliceState {
  id: number;
  status: SaleStatusTypes;
  subtotal: string;
  subtotalCents: number;
  client: any;
  created_by: any;
  totalCents: number;
  total: string;
  salesMerchantServices: any[];
  paidBalanceCents: number;
  paidBalance: string;
  paidOnlineAmountCents: number;
  paidOnlineAmount: string;
  paymentTransactions: any[];
  paymentLinks: any;
  latestPendingPaymentLink: any;
  paidTax: string; // Formatted currency string
  paidTaxCents: number;
  paidTips: string; // Formatted currency string
  taxCents: number;
  paidTipsCents: number;
  presaleTipsCents: number;
  dualPricingMethod: DualPricingPaymentTypes;
  unpaidMerchantServices: any[];
  archivedAt: string;
  createdAt: string;
  updatedAt: string;
}

interface SalesSliceReduxState {
  sales: SalesSliceState[];
  meta: {
    totalPages: number;
    currentPage: number;
    perPage: number;
    totalCount: number;
  };
}

const initialState = {
  sales: [],
  meta: {
    totalPages: 0,
    currentPage: 0,
    perPage: 0,
    totalCount: 0,
  },
} as SalesSliceReduxState;

export const getSales = createAsyncThunk(
  "sales/getSales",
  async (
    {
      page = 1,
      perPage = 30,
      sortBy = "created_at",
    }: {
      page?: number;
      perPage?: number;
      sortBy?: string;
    },
    thunkAPI
  ) => {
    try {
      const { data } = await http.get(
        `/v1/sales?page=${page}&per_page=${perPage}&sort_by=${sortBy}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createSale = createAsyncThunk(
  "sales/createSale",
  async (
    {
      subtotalCents,
      clientId,
      createdById,
      merchantServiceIds,
    }: {
      subtotalCents: number;
      clientId: number;
      createdById: number;
      merchantServiceIds: number[];
    },
    thunkAPI
  ) => {
    try {
      const { data } = await http.post("/v1/sales", {
        sale: {
          subtotalCents,
          clientId,
          createdById,
          merchantServiceIds,
          // notesAttributes: [{ content: note }],
        },
      });

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getSale = createAsyncThunk(
  "sales/getSale",
  async (
    {
      id,
      asCashDualPricing = false,
    }: { id: number; asCashDualPricing?: boolean },
    thunkAPI
  ) => {
    try {
      const { data } = await http.get(`/v1/sales/${id}`, {
        params: {
          asCashDualPricing,
          snakeCase: true,
        },
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateSale = createAsyncThunk(
  "sales/updateSale",
  async (sale: any, thunkAPI) => {
    try {
      const { data } = await http.patch(`/v1/sales/${sale.id}`, {
        sale,
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Only voids payments and sale
export const voidSale = createAsyncThunk(
  "sales/voidSale",
  async (
    { id, archiveSale = true }: { id: number; archiveSale?: boolean },
    thunkAPI
  ) => {
    try {
      const { data } = await http.post(`/v1/sales/${id}/void`, {
        archiveSale,
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const archiveSale = createAsyncThunk(
  "sales/archiveSale",
  async (saleId: number, thunkAPI) => {
    try {
      const { data } = await http.post(`/v1/sales/${saleId}/archive`, {});
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const salesSlice = createSlice({
  name: "sales",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSales.fulfilled, (state, action) => {
      const { sales: newSales, meta } = action.payload;
      // Directly set the new sales and meta information
      state.sales = newSales;
      state.meta = meta;
    });
    builder.addCase(getSale.fulfilled, (state, action) => {
      const index = state.sales.findIndex(
        (sale) => sale.id === action.payload.id
      );
      state.sales[index] = action.payload;
    });
    builder.addCase(createSale.fulfilled, (state, action) => {
      state.sales.unshift(action.payload);
    });
    builder.addCase(updateSale.fulfilled, (state, action) => {
      const index = state.sales.findIndex(
        (sale) => sale.id === action.payload.id
      );
      state.sales[index] = action.payload;
    });
    builder.addCase(voidSale.fulfilled, (state, action) => {
      state.sales = state.sales.map((sale) =>
        sale.id === action.payload.id ? action.payload : sale
      );
    });
    builder.addCase(archiveSale.fulfilled, (state, action) => {
      const index = state.sales.findIndex(
        (sale) => sale.id === action.payload.id
      );
      if (index !== -1) {
        state.sales.splice(index, 1); // Use splice to remove the sale in place
      }
    });
  },
});

export const selectSales = (state: RootState) => state.sales;
