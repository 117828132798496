import * as React from "react";
import { useReadAppointmentScreen } from "./useReadAppointmentScreen";
import {
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Spinner,
} from "@nextui-org/react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { format } from "date-fns";
import { CustomClientOption } from "app/schedules/components/crudAppointmentScreens/components/customClientOption";
import { CustomServiceOption } from "app/schedules/components/crudAppointmentScreens/components/customServiceOption";
import { CustomEmployeeOption } from "app/schedules/components/crudAppointmentScreens/components/customEmployeeOption";
import classNames from "classnames";
import { adjustDateToUTC } from "app/utils/formatTime";
import { preserveUtcTimeToLocal } from "app/utils/formatDate";
import {
  AsButton,
  AsIconButton,
  AsInput,
  reactSelectStyles,
} from "app/subframe";
import { Avatar } from "app/components/avatar/avatar";

export interface ReadAppointmentScreenProps {
  selectedAppointmentEvent: any;
  getSchedulesOfEmployees: ({ currentDate }: { currentDate?: Date }) => void;
  toggleAppointmentDrawer: (open: boolean) => void;
  setPendingAppointment: (appointment: any) => void;
  closeDrawer: () => void;
}

export const ReadAppointmentScreen: React.FC<ReadAppointmentScreenProps> = ({
  selectedAppointmentEvent,
  getSchedulesOfEmployees,
  toggleAppointmentDrawer,
  setPendingAppointment,
  closeDrawer,
}) => {
  const {
    isEditing,
    setIsEditing,
    employees,
    onUpdateAppointment,
    selectedClient,
    setSelectedClient,
    onHandleClientChange,
    clients,
    selectedServices,
    editingEmployeeInServiceIndex,
    setEditingEmployeeInServiceIndex,
    employeeSelectRef,
    handleEmployeeChange,
    serviceSelectValue,
    onHandleAddService,
    availableServices,
    selectedDate,
    setSelectedDate,
    isDateInputFocused,
    setIsDateInputFocused,
    onRemoveService,
    getSpecificAppointmentGroup,
    cancelEditing,
    onDeleteAppointmentGroup,
    handleClientSearch,
    onTimeChange,
    onDateChange,
  } = useReadAppointmentScreen({
    selectedAppointmentEvent,
    getSchedulesOfEmployees,
    toggleAppointmentDrawer,
    setPendingAppointment,
  });

  const { start, employee } = selectedAppointmentEvent;

  if (!selectedServices) {
    return <Spinner />;
  }

  const specificAppointmentGroup = getSpecificAppointmentGroup({
    appointmentId: selectedAppointmentEvent.appointment.id,
    phantomId: selectedAppointmentEvent.appointment.phantomId,
  });

  return (
    <form onSubmit={onUpdateAppointment} className="relative h-[95vh]">
      <div className="flex w-full items-center justify-between mb-7">
        <p className="text-body-bold font-body-bold text-neutral-900">
          Appointments
        </p>
        <div className="flex items-center ">
          {isEditing ? (
            <AsButton
              variant="ghost"
              className="rounded"
              onClick={() => cancelEditing()}
              size="sm"
              text="Cancel"
            />
          ) : (
            <div className="flex items-center gap-4">
              <AsIconButton
                size="xs"
                variant="ghost"
                className="rounded"
                leadingIcon={"FeatherPen"}
                onClick={() => setIsEditing(true)}
              />
              <Dropdown
                classNames={{
                  content: "rounded-sm",
                }}
              >
                <DropdownTrigger>
                  <AsIconButton
                    size="xs"
                    leadingIcon="FeatherMoreHorizontal"
                    variant="ghost"
                  />
                </DropdownTrigger>
                <DropdownMenu>
                  <DropdownItem
                    variant="flat"
                    key="delete"
                    className="rounded-sm "
                    color="danger"
                    onClick={onDeleteAppointmentGroup}
                  >
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <AsIconButton
                size="xs"
                variant="ghost"
                className="rounded"
                leadingIcon={"FeatherX"}
                onClick={() => closeDrawer()}
              />
            </div>
          )}
        </div>
      </div>

      <div className="flex items-center gap-2.5 mb-6 !select-none">
        <AsInput
          aria-label="Date Input"
          key={
            isEditing && isDateInputFocused
              ? "editing-date"
              : "not-editing-date"
          }
          disabled={!isEditing}
          name="date"
          defaultValue={
            isEditing && isDateInputFocused
              ? format(selectedDate, "yyyy-MM-dd")
              : format(selectedDate, "EEE, MMM d")
          }
          type={isEditing && isDateInputFocused ? "date" : "string"}
          textPosition="left"
          onFocus={() => setIsDateInputFocused(true)}
          containerClassName="w-full"
          onChange={(e: any) => {
            const dateValue = e.target.value;
            if (dateValue) {
              const adjustedDate = adjustDateToUTC(dateValue);
              setSelectedDate(adjustedDate.toISOString());
              onDateChange(adjustedDate);
            }
          }}
          showDisabledStyles={false}
          onBlur={() => setIsDateInputFocused(false)}
          leading="On"
        />
        <AsInput
          key={selectedAppointmentEvent.appointment.id}
          id="startTime"
          aria-label="Start time Input"
          className="!bg-transparent !select-none"
          containerClassName="w-full"
          defaultValue={
            specificAppointmentGroup.appointments[0]?.startTime
              ? format(
                  preserveUtcTimeToLocal(
                    specificAppointmentGroup.appointments[0]?.startTime
                  ),
                  "HH:mm"
                )
              : "00:00"
          }
          type="time"
          textPosition="left"
          disabled={!isEditing}
          showDisabledStyles={false}
          onChange={onTimeChange}
          name="startTime"
          leading={"Start at"}
        />
      </div>

      <div className="flex flex-col relative h-[70dvh] pb-28 overflow-auto">
        {isEditing && (
          <AsIconButton
            variant="ghost"
            className="rounded absolute top-0 right-0"
            leadingIcon={"FeatherTrash2"}
            size="xs"
          />
        )}
        {selectedClient ? (
          <div className="flex flex-col relative">
            {isEditing && (
              <AsIconButton
                variant="ghost"
                onClick={() => setSelectedClient(null)}
                className="rounded absolute top-0 right-0"
                leadingIcon={"FeatherTrash2"}
                size="xs"
              />
            )}
            <div className="flex pb-4">
              <Avatar
                src={selectedClient.avatar}
                name={selectedClient.name}
                size={56}
                type="character"
              />
              <div className="flex flex-col justify-between ml-4">
                <p className="font-semibold pt-0.5">{selectedClient.name}</p>
                <p className="text-zinc-500 text-sm">
                  Client since {format(selectedClient.createdAt, "M.d.yyyy")}
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-4">
                <p className="text-zinc-500 w-[56px] text-sm">Phone:</p>
                <p className="font-semibold ">
                  {selectedClient.phone || "(###) ###-####"}
                </p>
              </div>
              <div className="flex items-center gap-4">
                <p className="text-zinc-500 w-[56px] text-sm">Email:</p>
                <p className="font-semibold ">
                  {selectedClient.email || "###"}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <AsyncSelect
            cacheOptions
            loadOptions={handleClientSearch as any}
            onChange={onHandleClientChange}
            className="text-zinc-500 text-sm"
            placeholder="Search or select a client"
            components={{
              Option: CustomClientOption,
            }}
            options={clients.map((c) => ({
              value: c.id,
              label: c.name,
              phone: c.phone,
              email: c.email,
              createdAt: c.createdAt,
              avatar: c.avatar,
            }))}
            styles={reactSelectStyles({
              borderContainer: true,
            })}
          />
        )}
        <Divider className="my-6" />

        <div className="flex flex-col gap-6 mb-6">
          {selectedServices.map((service: any, index: number) => (
            <div
              className="flex relative items-end justify-between"
              key={service.id}
            >
              <div className="flex flex-col gap-3">
                <p className="text-body-bold font-body-bold text-neutral-900">
                  {service.title}
                </p>
                <div className="flex gap-1 items-center">
                  <span className="text-zinc-500 text-sm">with</span>
                  <div
                    className={classNames(
                      "font-semibold text-zinc-900 relative mr-3",
                      {
                        "cursor-pointer hover:underline": isEditing,
                      }
                    )}
                    onClick={() =>
                      isEditing && setEditingEmployeeInServiceIndex(index)
                    }
                  >
                    {editingEmployeeInServiceIndex === index && isEditing ? (
                      <div
                        ref={employeeSelectRef}
                        className="absolute -top-5 w-[200px]"
                      >
                        <Select
                          placeholder="Select an employee"
                          components={{
                            Option: CustomEmployeeOption,
                          }}
                          onChange={(selectedOption) =>
                            handleEmployeeChange(index, selectedOption)
                          }
                          className="text-zinc-500 text-sm"
                          options={employees.map((emp) => ({
                            value: emp.id,
                            label: emp.name,
                            services: emp.services,
                            selectedDate,
                            availabilities: emp.availabilities,
                            businessServiceId:
                              selectedServices[index]?.businessService.id, // Pass the specific business service ID
                          }))}
                          onBlur={() => setEditingEmployeeInServiceIndex(null)}
                          value={
                            selectedServices[index]?.employee
                              ? {
                                  value: selectedServices[index]?.employee?.id,
                                  label:
                                    selectedServices[index]?.employee?.name,
                                }
                              : null
                          }
                          menuIsOpen={editingEmployeeInServiceIndex === index}
                          styles={reactSelectStyles({})}
                        />
                      </div>
                    ) : (
                      <p className="text-sm">
                        <span className="font-semibold text-zinc-900">
                          {service?.employee?.name}
                        </span>
                      </p>
                    )}
                  </div>
                  <p
                    className={classNames("flex gap-1 text-sm", {
                      "ml-[200px]": editingEmployeeInServiceIndex === index,
                    })}
                  >
                    <span className="text-zinc-500 text-sm">At</span>
                    <span className="font-semibold text-zinc-900">
                      {service.startAtTime}
                    </span>
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <p className="text-body-bold font-body-bold text-neutral-900">
                  {service.price}
                </p>
                {isEditing && (
                  <AsButton
                    type="button"
                    onClick={() => onRemoveService(index)}
                    variant="ghost"
                    className="absolute top-0 right-0"
                    size="xs"
                    leadingIcon={"FeatherTrash2"}
                  />
                )}
              </div>
            </div>
          ))}
        </div>

        {isEditing && (
          <Select
            placeholder="Search or select a service"
            components={{
              Option: CustomServiceOption,
            }}
            onChange={onHandleAddService}
            value={serviceSelectValue}
            className="text-zinc-500 text-sm"
            options={availableServices?.map((category) => ({
              label: category.name,
              options: category.services.map((service: any) => ({
                value: service.id,
                label: service.title,
                price: service.price,
                status: service.status,
                employee,
              })),
            }))}
            styles={reactSelectStyles({
              borderContainer: true,
            })}
          />
        )}

        {!selectedAppointmentEvent.appointment.phantomId && (
          <>
            <Divider className="my-6" />
            <div className="flex flex-col gap-4">
              <span className="text-sm text-default-700">Booking details</span>

              {specificAppointmentGroup.givenConsent && (
                <div className="flex flex-col gap-1">
                  <p className="text-default-700 text-sm font-semibold">
                    Consent given
                  </p>
                  <p className="text-foreground-500 text-sm">
                    {format(
                      selectedAppointmentEvent.appointment.createdAt,
                      "MMM dd, h:MM a"
                    )}{" "}
                    • Provided by{" "}
                    {selectedAppointmentEvent.appointment.client.name}
                  </p>
                </div>
              )}
              <div className="flex flex-col gap-1">
                <p className="text-default-700 text-sm font-semibold">
                  Booking created
                </p>
                <p className="text-foreground-500 text-sm">
                  {format(
                    selectedAppointmentEvent.appointment.createdAt,
                    "MMM dd, h:MM a"
                  )}
                </p>
              </div>
            </div>
          </>
        )}
      </div>

      {isEditing && (
        <AsButton
          type="submit"
          className="rounded-sm w-full bottom-0 absolute"
          text="Update appointment"
          size="md"
        />
      )}
    </form>
  );
};
