import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "app/utils/useQuery";
import { selectAccount } from "app/redux/accountSlice";
import { toast } from "react-toastify";
import { find, round } from "lodash";
import paymentFormStyle from "./paymentFormStyle";
import { payWithCreditCard } from "app/redux/paymentTransactionSlice";
import { SaleStatusTypes, selectSales, updateSale } from "app/redux/salesSlice";
import { useNavigate } from "react-router-dom";

export const testCreditCards = [
  {
    cardType: "Visa",
    cardNumber: "4761530001111118",
    expirationDate: "12/25", // MM/YY
    cvv: "123",
    cardholderName: "John Doe",
    zipCode: 94016,
  },
  {
    cardType: "MasterCard",
    cardNumber: "5137221111116668",
    expirationDate: "11/24", // MM/YY
    cvv: "456",
    cardholderName: "Jane Smith",
    zipCode: 94016,
  },
];

export const useInAppCcCheckout = ({
  total,
  tipsCents,
  totalCents,
  onPostPaymentRecord,
}: {
  total: string;
  tipsCents: number;
  totalCents: number;
  onPostPaymentRecord: (sale: any) => void;
}) => {
  const query = useQuery();
  const selectedDualPricingMethod = query.get("dualPricingPaymentMethod");
  const navigate = useNavigate();
  const saleIdParam = query.get("saleId");
  const { sales } = useSelector(selectSales);
  const sale = find(sales, { id: Number(saleIdParam) });
  const dispatch = useDispatch();
  const account = useSelector(selectAccount);
  const [processingCreditCardCharge, setProcessingCreditCardCharge] =
    useState(false);
  const cardFormRef: any = useRef(null);

  const onCreditCardPayment = async (event: any) => {
    event.preventDefault();
    setProcessingCreditCardCharge(true);
    try {
      const result = await cardFormRef.current.getNonceToken();
      const transactionTaxCents = selectedDualPricingMethod
        ? sale!.taxCents
        : sale!.taxCents - sale!.paidTaxCents;

      const shouldVoidPreviousPayments =
        account.dualPricingEnabled &&
        sale!.paidBalanceCents > 0 &&
        sale!.unpaidMerchantServices.length > 0;

      const creditCardRes = await dispatch(
        payWithCreditCard({
          source: `nonce-${result.nonce}`,
          amount: shouldVoidPreviousPayments
            ? Number(total)
            : round(totalCents / 100, 2),
          totalCents,
          subtotalCents: sale!.subtotalCents,
          taxCents: transactionTaxCents,
          tipsCents,
          saleId: sale!.id,
          name: sale!.client.name,
          expiryMonth: result.expiryMonth,
          expiryYear: result.expiryYear,
          tax: round(transactionTaxCents / 100, 2),
          tip: round(tipsCents / 100, 2),
          voidPreviousPayments:
            account.dualPricingEnabled &&
            sale!.paidBalanceCents > 0 &&
            sale!.unpaidMerchantServices.length > 0,
        }) as any
      ).unwrap();

      closeSale(creditCardRes.sale);
      toast.success("Credit card payment successful");
    } catch (error: any) {
      console.log(error);
      if (error?.data?.error?.status === "Declined") {
        toast.error("Card declined. Please try another card.");
        cardFormRef.current.resetForm();
      }
      if (error?.data?.error?.status === "Error") {
        toast.error("An error occurred. Please try again.");
        cardFormRef.current.resetForm();
      }
      setProcessingCreditCardCharge(false);
    }
  };

  const closeSale = async (newlyPaidSale: any) => {
    try {
      const newSale = await dispatch(
        updateSale({
          id: newlyPaidSale.id,
          status: SaleStatusTypes.CLOSED,
          dualPricingMethod: selectedDualPricingMethod,
        }) as any
      ).unwrap();
      onPostPaymentRecord(newSale);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (account.acceptBlueTokenizationApi) {
      const initializeHostedTokenization = async () => {
        const hostedTokenization = new window.HostedTokenization(
          account.acceptBlueTokenizationApi
        );
        cardFormRef.current = await hostedTokenization.create("card-form");
        await cardFormRef.current.mount("#accept-blue-iframe");
        await cardFormRef.current.setStyles(paymentFormStyle);
      };

      initializeHostedTokenization();
    }
  }, [account.acceptBlueTokenizationApi, cardFormRef]);

  return {
    processingCreditCardCharge,
    onCreditCardPayment,
    cardFormRef,
    account,
  };
};
