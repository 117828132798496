"use client";

import React from "react";
import * as SubframeCore from "@subframe/core";

interface DualButtonsRootProps extends React.HTMLAttributes<HTMLDivElement> {
  icon?: SubframeCore.IconName;
  payType?: React.ReactNode;
  price?: React.ReactNode;
  text3?: React.ReactNode;
  variant?: "default" | "cash";
  selected?: boolean;
  className?: string;
}

const DualButtonsRoot = React.forwardRef<HTMLElement, DualButtonsRootProps>(
  function DualButtonsRoot(
    {
      icon = "FeatherCreditCard",
      payType,
      price,
      text3,
      variant = "default",
      selected = false,
      className,
      ...otherProps
    }: DualButtonsRootProps,
    ref
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          "group/81155a06 flex w-full cursor-pointer flex-col items-center gap-4 rounded-md border border-solid border-neutral-300 bg-neutral-0 px-4 py-6 hover:border hover:border-solid hover:border-neutral-500 hover:bg-white active:border active:border-solid active:border-neutral-900 active:bg-neutral-900",
          { "border border-solid border-neutral-900 bg-neutral-900": selected },
          className
        )}
        ref={ref as any}
        {...otherProps}
      >
        <div className="flex w-full flex-col items-center gap-1">
          <div
            className={SubframeCore.twClassNames(
              "flex w-full items-center gap-4 py-6",
              { "px-0 pt-6 pb-0": variant === "cash" }
            )}
          >
            <div className="flex grow shrink-0 basis-0 items-center gap-4">
              <div
                className={SubframeCore.twClassNames(
                  "flex h-8 w-10 flex-none items-center justify-center gap-2 rounded-md border border-solid border-success-400 bg-success-50 px-2 py-1",
                  {
                    "border border-solid border-blue-gray-400 bg-blue-gray-50":
                      variant === "cash",
                  }
                )}
              >
                <SubframeCore.Icon
                  className="text-body font-body text-default-font"
                  name={icon}
                />
              </div>
              {payType ? (
                <span
                  className={SubframeCore.twClassNames(
                    "text-body-bold font-body-bold text-neutral-900 text-center group-active/81155a06:text-white",
                    { "text-white": selected }
                  )}
                >
                  {payType}
                </span>
              ) : null}
            </div>
            {price ? (
              <span
                className={SubframeCore.twClassNames(
                  "font-['Inter'] text-[24px] font-[600] leading-[32px] text-neutral-900 text-right group-active/81155a06:text-white",
                  { "text-white": selected }
                )}
              >
                {price}
              </span>
            ) : null}
          </div>
          {text3 ? (
            <span
              className={SubframeCore.twClassNames(
                "hidden w-full font-['Inter'] text-[14px] font-[400] leading-[20px] text-neutral-900 text-right group-active/81155a06:text-white",
                { "text-white": selected, inline: variant === "cash" }
              )}
            >
              {text3}
            </span>
          ) : null}
        </div>
      </div>
    );
  }
);

export const DualButton = DualButtonsRoot;
