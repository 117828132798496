import * as React from "react";
import { AsButton } from "app/subframe";
import { PaymentModalHeader, AsInput } from "app/subframe";
import { truncate } from "lodash";
import { CopyButton } from "app/components/copyButton";
import { usePaymentLinkCcCheckout } from "./usePaymentLinkCcCheckout";
import classNames from "classnames";
import { useBreakpoint } from "app/utils/useBreakpoint";

export interface PaymentLinkCcCheckoutProps {
  setCreditCardOption: (mode: any) => void;
  paymentLink: any;
  sale: any;
}

export const PaymentLinkCcCheckout: React.FC<PaymentLinkCcCheckoutProps> = ({
  setCreditCardOption,
  paymentLink,
  sale,
}) => {
  const {
    copyPaymentLink,
    showCopiedText,
    emailToSendPaymentLink,
    setEmailToSendPaymentLink,
    onSendPaymentLinkWithEmail,
  } = usePaymentLinkCcCheckout({
    paymentLink,
    sale,
  });

  const { isBelowSm } = useBreakpoint("sm");

  const copyPaymentLinkComponent = () => (
    <div className="flex relative justify-between w-full items-center gap-4">
      <span
        className="shrink-0 py-1 pr-1 basis-0 text-body font-body cursor-pointer hover:underline text-neutral-700"
        onClick={copyPaymentLink}
      >
        {truncate(paymentLink.link, {
          length: 25,
          omission: "...",
        })}
        <span
          className={classNames(
            "absolute z-50 bg-neutral-100 translate-x-2 duration-100 text-sm rounded p-1 font-semibold text-neutral-900",
            {
              "opacity-0": !showCopiedText,
              "opacity-100": showCopiedText,
            }
          )}
        >
          Copied!
        </span>
      </span>
      <CopyButton textToCopy={paymentLink.link} />
    </div>
  );

  return (
    <>
      {isBelowSm ? (
        <>
          {isBelowSm && (
            <div className="fixed top-0 left-0 h-screen w-screen bg-[#171718]/80" />
          )}
          <div className="flex fixed bottom-0 left-0 rounded-t-lg flex-col items-center gap-6 bg-white px-2 py-4 shadow-pop-up-shadow mobile:w-full">
            <PaymentModalHeader
              text="Pay with link"
              buttonProps={{
                onClick: () => setCreditCardOption(null),
              }}
            />
            {copyPaymentLinkComponent()}
            <span className="w-full text-body-bold font-body-bold text-neutral-900">
              Or send to user's email
            </span>
            <AsInput
              value={emailToSendPaymentLink}
              onChange={(e) => setEmailToSendPaymentLink(e.target.value)}
              error={false}
              showLeadingIcon={false}
              disabled={false}
              textPosition="left"
              type="email"
              containerClassName="w-full"
            />
            <AsButton
              className="h-12 w-full flex-none"
              variant="secondary"
              leadingIcon="FeatherMail"
              trailingIcon={null}
              text="Send now"
              size="md"
              loader={false}
              onClick={() => onSendPaymentLinkWithEmail()}
            />
          </div>
        </>
      ) : (
        <>
          <PaymentModalHeader
            text="Pay with link"
            size="small"
            buttonProps={{
              onClick: () => setCreditCardOption(null),
            }}
          />
          <div className="flex h-px w-full flex-none flex-col items-center gap-2 bg-neutral-100" />
          {copyPaymentLinkComponent()}
          <span className="w-full text-body-bold font-body-bold text-neutral-900">
            Or send to user's email
          </span>
          <AsInput
            value={emailToSendPaymentLink}
            onChange={(e) => setEmailToSendPaymentLink(e.target.value)}
            error={false}
            showLeadingIcon={false}
            disabled={false}
            textPosition="left"
            type="email"
            containerClassName="w-full"
          />
          <AsButton
            className="h-10 w-32 flex-none"
            variant="secondary"
            leadingIcon="FeatherMail"
            text="Send now"
            size="default"
            loader={false}
            onClick={() => onSendPaymentLinkWithEmail()}
          />
        </>
      )}
    </>
  );
};
