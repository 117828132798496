import * as React from "react";
import { useInAppCcCheckout } from "./useInAppCcCheckout";
import { FadeInSlide } from "app/motions/fadeInSlide";
import { Button, Link, Spinner } from "@nextui-org/react";
import classNames from "classnames";
import { AsButton, PaymentModalHeader } from "app/subframe";
import { useBreakpoint } from "app/utils/useBreakpoint";

export interface InAppCcCheckoutProps {
  total: string;
  tipsCents: number;
  totalCents: number;
  setCreditCardOption: (option: any) => void;
  onPostPaymentRecord: (sale: any) => void;
}

export const InAppCcCheckout: React.FC<InAppCcCheckoutProps> = ({
  total,
  tipsCents,
  totalCents,
  setCreditCardOption,
  onPostPaymentRecord,
}) => {
  const { processingCreditCardCharge, onCreditCardPayment } =
    useInAppCcCheckout({
      total,
      tipsCents,
      totalCents,
      onPostPaymentRecord,
    });

  const { isBelowSm } = useBreakpoint("sm");

  const acceptBlueIframe = () => (
    <div className="flex flex-col gap-2 w-full h-[110px]">
      <div id="accept-blue-iframe" />
    </div>
  );

  return (
    <>
      <form
        onSubmit={onCreditCardPayment}
        className={classNames("flex flex-col h-full gap-4 w-full")}
      >
        {isBelowSm ? (
          <>
            {isBelowSm && (
              <div className="fixed top-0 left-0 h-screen w-screen bg-[#171718]/80" />
            )}
            <div className="flex fixed bottom-0 left-0 rounded-t-lg flex-col items-center gap-6 bg-white px-2 py-4 shadow-pop-up-shadow mobile:w-full">
              <PaymentModalHeader
                text="Pay on app"
                buttonProps={{
                  onClick: () => setCreditCardOption(null),
                }}
              />
              <div className="flex w-full flex-col items-center gap-6">
                <div className="flex w-full flex-col items-center gap-4 rounded-md border border-solid border-neutral-100 bg-neutral-0 px-3 py-4">
                  <span className="w-full text-body-bold font-body-bold text-neutral-900">
                    CC Info
                  </span>
                  {acceptBlueIframe()}
                </div>
              </div>
              <AsButton
                className="h-14 w-full flex-none"
                variant="default"
                leadingIcon={null}
                trailingIcon={null}
                text="Pay now"
                size="lg"
                disabled={processingCreditCardCharge}
                loader={processingCreditCardCharge}
                type="submit"
              />
            </div>
          </>
        ) : (
          <>
            <PaymentModalHeader
              text="Pay on app"
              size="small"
              buttonProps={{
                onClick: () => setCreditCardOption(null),
              }}
            />
            {acceptBlueIframe()}
            <AsButton
              className="h-12 w-full flex-none"
              variant="default"
              text="Pay now"
              size="md"
              type="submit"
              disabled={processingCreditCardCharge}
              loader={processingCreditCardCharge}
            />
          </>
        )}
      </form>
    </>
  );
};
