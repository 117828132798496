import { createAsyncThunk } from "@reduxjs/toolkit";
import http from "app/requests/axiosInstance";
import { getSale } from "./salesSlice";

export const getPaymentLink = createAsyncThunk(
  "paymentLink/getPaymentLink",
  async (paymentLinkId: string) => {
    const { data } = await http.get(`/v1/payment_links/${paymentLinkId}`);
    return data;
  }
);

export const createPaymentLink = createAsyncThunk(
  "paymentLink/createPaymentLink",
  async (saleId: any, thunkAPI: any) => {
    const { data } = await http.post(`/v1/payment_links`, { saleId });
    thunkAPI.dispatch(getSale({ id: data.saleId }));
    return data;
  }
);

export const updatePaymentLink = createAsyncThunk(
  "paymentLink/updatePaymentLink",
  async (paymentLink: any) => {
    const { data } = await http.patch(
      `/v1/payment_links/${paymentLink.id}`,
      paymentLink
    );
    return data;
  }
);

export const sendPaymentLinkWithEmail = createAsyncThunk(
  "paymentLink/sendPaymentLinkEmail",
  async ({ id, email }: { id: number; email?: string }) => {
    const { data } = await http.post(
      `/v1/payment_links/${id}/send_email_payment_link`,
      {
        email,
      }
    );
    return data;
  }
);

// export const delete = createAsyncThunk(
// );
