import { FadeInSlide } from "app/motions/fadeInSlide";
import { DualPricingPaymentTypes } from "app/sales/useSales";
import { AsIconButton, DialogLayout } from "app/subframe";
import { DualButton } from "app/subframe/components/DualButton";
import * as React from "react";

export interface DualPricingModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  creditCardPrice: string;
  cashPrice: string;
  onPayBy: (method: DualPricingPaymentTypes) => void;
}

export const DualPricingModal: React.FC<DualPricingModalProps> = (props) => {
  const { open, onOpenChange, creditCardPrice, cashPrice, onPayBy } = props;
  return (
    <FadeInSlide direction="bottom" className="absolute">
      <DialogLayout
        open={open}
        onOpenChange={onOpenChange}
        className="z-[10000]"
      >
        <div className="flex h-144 w-192 flex-col items-center gap-4 rounded-lg bg-default-background px-6 pt-6 pb-10 shadow-dialog-shadow mobile:h-144 mobile:w-96 mobile:px-2 mobile:pt-2 mobile:pb-4">
          <div className="flex w-full items-start justify-end gap-6">
            <div className="flex grow shrink-0 basis-0 flex-col items-start justify-center gap-2">
              <span className="text-body-bold font-body-bold text-neutral-900">
                Show this to your customer
              </span>
              <span className="font-['Inter'] text-[14px] font-[400] leading-[20px] text-neutral-700">
                Choose a payment method
              </span>
            </div>
            <AsIconButton
              className="h-8 w-8 flex-none"
              variant="ghost"
              leadingIcon="FeatherX"
              trailingIcon={null}
              text="Label"
              size="default"
              disabled={false}
              disabledSecondary={false}
              onClick={() => onOpenChange(false)}
            />
          </div>
          <div className="flex w-112 flex-col items-center gap-10 pt-10 mobile:h-auto mobile:w-full mobile:flex-none">
            <DualButton
              icon="FeatherCreditCard"
              payType="Pay by Card"
              price={creditCardPrice}
              text3=""
              onClick={() => onPayBy(DualPricingPaymentTypes.CARD)}
            />
            <DualButton
              icon="FeatherBanknote"
              payType="Pay by Cash"
              price={cashPrice}
              text3="(Cash Discount)"
              variant="cash"
              onClick={() => onPayBy(DualPricingPaymentTypes.CASH)}
            />
          </div>
        </div>
      </DialogLayout>
    </FadeInSlide>
  );
};
