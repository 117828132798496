import { Path } from "app/path";
import { selectAccount } from "app/redux/accountSlice";
import { selectBusinessServices } from "app/redux/businessServicesSlice";
import { selectClients } from "app/redux/clientsSlice";
import { selectEmployees } from "app/redux/employeesSlice";
import { createSale, selectSales, updateSale } from "app/redux/salesSlice";
import { selectUser } from "app/redux/userSlice";
import {
  DualPricingPaymentTypes,
  SaleStatusConditionTypes,
} from "app/sales/useSales";
import { useQuery } from "app/utils/useQuery";
import { find, sumBy, round, replace } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const useNewCheckout = ({
  onArchiveSale,
  saleStatusCondition,
  setSaleStatusCondition,
  selectCorrespondingScreen,
}: {
  onArchiveSale: any;
  saleStatusCondition: any;
  setSaleStatusCondition: any;
  selectCorrespondingScreen: any;
}) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const account = useSelector(selectAccount);
  const saleIdParam = query.get("saleId");
  const { sales } = useSelector(selectSales);
  const sale = find(sales, (sale: any) => sale.id === Number(saleIdParam));
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const { clients } = useSelector(selectClients);
  const employees = useSelector(selectEmployees);
  const employee = employees[0];
  const businessServices = useSelector(selectBusinessServices);
  const [availableServices, setAvailableServices] = useState(businessServices);
  const [selectedClient, setSelectedClient] = useState<any>(null);
  const [selectedServices, setSelectedServices] = useState<any>([]);
  const [serviceSelectValue, setServiceSelectValue] = useState(null);
  const [editingEmployeeInServiceIndex, setEditingEmployeeInServiceIndex] =
    useState<number | null>(null);
  const employeeSelectRef = useRef<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [onShowDeleteModal, setOnShowDeleteModal] = useState(false);
  const [showDualPricingModal, setShowDualPricingModal] = useState(false);

  const onHandleClientChange = (selectedOption: any) => {
    setSelectedClient({
      ...selectedOption,
      id: selectedOption.value,
      name: selectedOption.label,
    });
  };

  const handleEmployeeChange = (serviceIndex: number, selectedOption: any) => {
    const updatedServices = [...selectedServices];
    const newEmployee = employees.find(
      (emp) => emp.id === selectedOption.value
    );
    const newEmployeeService = newEmployee?.services.find(
      (service) =>
        service.businessService.id ===
        updatedServices[serviceIndex].businessService.id
    );
    updatedServices[serviceIndex] = {
      ...updatedServices[serviceIndex],
      employee: newEmployee,
      id: newEmployeeService.id,
      price: newEmployeeService.price,
      priceCents: newEmployeeService.priceCents,
      status: newEmployeeService.status,
      duration: newEmployeeService.duration,
    };
    setSelectedServices(updatedServices);
    setTimeout(() => {
      setEditingEmployeeInServiceIndex(null);
    }, 0);
  };

  const onHandleAddService = (selectedOption: any) => {
    const employeeService = find(
      employee!.services,
      (service) => service.businessService.id === selectedOption.value
    );

    setSelectedServices([...selectedServices, employeeService]);

    const updatedAvailableServices = availableServices.map((category) => ({
      ...category,
      services: category.services.filter(
        (service: any) => service.id !== selectedOption.value
      ),
    }));

    setAvailableServices(updatedAvailableServices);
    setServiceSelectValue(null); // Clear the Select component
  };

  const onRemoveService = (index: number) => {
    const updatedServices = [...selectedServices];
    updatedServices.splice(index, 1); // Remove the service at the specified index

    setSelectedServices(updatedServices);
  };

  const onPayBy = (paymentMethod: DualPricingPaymentTypes) => {
    const payload = {
      id: saleIdParam || null,
      subtotalCents: sumBy(selectedServices, "priceCents"),
      clientId: selectedClient.id,
      merchantServiceIds: selectedServices.map((service: any) => service.id),
      createdById: user.id,
    };
    onSaleSave(payload, paymentMethod);
  };

  const onSaveAndContinue = (event: any) => {
    event.preventDefault();
    if (!selectedClient) {
      toast.error("Please select a client");
      return;
    }

    if (selectedServices.length === 0) {
      toast.error("Please select at least one service");
      return;
    }
    // if dual pricing is enabled, show the dual pricing modal
    const shouldShowDualPricingModal =
      account.dualPricingEnabled &&
      (saleStatusCondition === SaleStatusConditionTypes.PRE_AUTHORIZED ||
        saleStatusCondition === SaleStatusConditionTypes.REMAINING_BALANCE ||
        !saleStatusCondition);
    if (shouldShowDualPricingModal) {
      setShowDualPricingModal(true);
    } else {
      const payload = {
        id: saleIdParam || null,
        subtotalCents: sumBy(selectedServices, "priceCents"),
        clientId: selectedClient.id,
        merchantServiceIds: selectedServices.map((service: any) => service.id),
        createdById: user.id,
      };
      onSaleSave(payload);
    }
  };

  const onSaleSave = async (
    payload: any,
    paymentMethod?: DualPricingPaymentTypes
  ) => {
    try {
      setIsLoading(true);
      const { id, status } = await dispatch(
        saleIdParam ? updateSale(payload) : (createSale(payload) as any)
      ).unwrap();
      query.set("saleId", id);
      query.set("status", status);
      query.set("screen", "payment");
      if (paymentMethod) {
        query.set("dualPricingPaymentMethod", paymentMethod);
      }
      navigate(`${Path.SALES}?${query.toString()}`);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const getSubtotal = ({
    method = DualPricingPaymentTypes.CARD,
  }: {
    method: DualPricingPaymentTypes;
  }) => {
    const cardSubtotal = sumBy(selectedServices, "priceCents") / 100;

    if (method === DualPricingPaymentTypes.CARD) {
      return `$${round(cardSubtotal, 2).toFixed(2)}`;
    } else {
      const cashSubtotal = cardSubtotal * 0.96;
      return `$${round(cashSubtotal, 2).toFixed(2)}`;
    }
  };

  const onArchiveSaleClick = () => {
    if (sale?.paidBalanceCents && sale?.paidBalanceCents > 0) {
      setOnShowDeleteModal(true);
    } else {
      onArchiveSale(sale);
    }
  };

  useEffect(() => {
    if (saleIdParam) {
      setSelectedClient(sale?.client);

      setSelectedServices(
        sale?.salesMerchantServices.map((sms: any) => sms.merchantService)
      );
    } else {
      setSelectedClient(null);
      setSelectedServices([]);
    }
  }, [saleIdParam]);

  useEffect(() => {
    if (sale) {
      const newSubtotalCents = sumBy(selectedServices, "priceCents");
      const { id, subtotalCents, paidBalanceCents } = sale;
      if (subtotalCents !== newSubtotalCents && paidBalanceCents > 0) {
        setSaleStatusCondition(SaleStatusConditionTypes.REMAINING_BALANCE);
      } else {
        selectCorrespondingScreen(sale);
      }
    }
  }, [sale, selectedServices]);

  return {
    clients,
    selectedClient,
    setSelectedClient,
    onHandleClientChange,
    selectedServices,
    editingEmployeeInServiceIndex,
    setEditingEmployeeInServiceIndex,
    employeeSelectRef,
    employees,
    handleEmployeeChange,
    onHandleAddService,
    serviceSelectValue,
    availableServices,
    employee,
    onRemoveService,
    onSaveAndContinue,
    isLoading,
    sale,
    getSubtotal,
    onShowDeleteModal,
    onArchiveSaleClick,
    setOnShowDeleteModal,
    showDualPricingModal,
    setShowDualPricingModal,
    onPayBy,
    account,
  };
};
